<template>
	<nav v-if="tablePagination ? tablePagination.total : false">
		<ul class="pagination float-left">
			Displaying
			{{
				tablePagination ? tablePagination.from : 0
			}}
			to
			{{
				tablePagination ? tablePagination.to : 0
			}}
			of
			{{
				tablePagination ? tablePagination.total : 0
			}}. Page
			{{
				tablePagination
					? tablePagination.current_page + "/" + tablePagination.last_page
					: "0/0"
			}}
		</ul>
		<ul class="pagination float-right">
			<li :class="['page-item', { disabled: isOnFirstPage }]">
				<a
					href="#"
					target="_self"
					class="page-link"
					@click.prevent="loadPage(1)"
				>
					<span>&laquo;&laquo;</span>
				</a>
			</li>

			<li :class="['page-item', { disabled: isOnFirstPage }]">
				<a
					href="#"
					target="_self"
					class="page-link"
					@click.prevent="loadPage('prev')"
				>
					<span>&laquo;</span>
				</a>
			</li>

			<template v-if="notEnoughPages">
				<li
					v-for="n in totalPage"
					:key="n"
					:class="['page-item', { active: isCurrentPage(n) }]"
				>
					<a
						class="page-link"
						href="#"
						target="_self"
						@click.prevent="loadPage(n)"
						v-html="n"
					></a>
				</li>
			</template>
			<template v-else>
				<li
					v-for="n in windowSize"
					:key="n"
					:class="['page-item', { active: isCurrentPage(windowStart + n - 1) }]"
				>
					<a
						class="page-link"
						href="#"
						target="_self"
						@click.prevent="loadPage(windowStart + n - 1)"
						v-html="windowStart + n - 1"
					></a>
				</li>
			</template>

			<li :class="['page-item', { disabled: isOnLastPage }]">
				<a
					class="page-link"
					href="#"
					target="_self"
					@click.prevent="loadPage('next')"
				>
					<span>&raquo;</span>
				</a>
			</li>

			<li :class="['page-item', { disabled: isOnLastPage }]">
				<a
					class="page-link"
					href="#"
					target="_self"
					@click.prevent="loadPage(totalPage)"
				>
					<span>&raquo;&raquo;</span>
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
import VuetablePaginationMixin from "vuetable-3/src/components/VuetablePaginationMixin.vue";

export default {
	mixins: [VuetablePaginationMixin],
};
</script>
