export const basuri =
	process.env.VUE_APP_STATUS === "prod"
		? process.env.VUE_APP_API_BASEURL_PROD
		: process.env.VUE_APP_API_BASEURL;

export const guard = function(access) {
	const { store } = require("../store/index");
	const permis = store.state.appinfo.permissions;

	//if (access == "all_access") return true;

	// console.log(store.state.appinfo.permissions);
	if (permis) {
		const key = "Pr4$eTy0 BoiL3rPlaT3";
		const aes256 = require("aes256");

		const permisDec = aes256.decrypt(key, permis);
		//const permisDec = permis

		if (permisDec.includes(access) || access == "all_access") {
			return true;
		} else return false;
	} else return false;
};

export const debounce = function(fn, delay) {
	let timeoutID = null;
	return function() {
		if (timeoutID) clearTimeout(timeoutID);

		const args = arguments;
		const self = this;

		timeoutID = setTimeout(function() {
			fn.apply(self, args);
		}, delay);
	};
};

export const getBase64 = function(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
};

export const baseUrlImage = function(value) {
	if (!value) return "";
	if (value.includes("data:image")) return value;
	return basuri.replace(/\/\s*$/, "") + value;
};

export const insurance = [
	"none",
	"BPJS",
	"Allianz",
	"Prudential",
	"AIA Financial",
	"AXA Mandiri",
	"Indolife",
	"Sequis",
	"BNI Life",
	"Reliance",
	"Sinarmas",
	"FWD",
];
