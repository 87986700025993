import Vue from 'vue';
import Vuex from 'vuex';
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import appinfo from './appinfo';
import menu from './menu';

const ls = new SecureLS({ isCompression: false });


Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
      appinfo,menu
    },
    plugins: [
      createPersistedState({
        key:'pras-bp',
        storage: {
          getItem: key => ls.get(key),
          setItem: (key, value) => ls.set(key, value),
          removeItem: key => ls.remove(key)
        }
      })
   ]
});

export default store